import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, Link, Typography, makeStyles } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import UserIcon from '../../icons/User';
import Logo from './Logo';
import NavSection from './NavSection';
import Scrollbar from './Scrollbar';
import AvatarInitials from 'src/pages/viola/Home/ui/AvatarInitials';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import TransformIcon from '@material-ui/icons/Transform';
import StoreIcon from '@material-ui/icons/Store';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  DriveEta,
  DriveEtaOutlined,
  PriceCheck,
  LocalShipping,
  DeliveryDining,
  Grading,
  QueryStats, ShoppingBasket, LocalHospital
} from "@material-ui/icons";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CategoryIcon from '@material-ui/icons/Category';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CampaignIcon from '@material-ui/icons/Campaign';
import AppsIcon from '@material-ui/icons/Apps';
import PrintIcon from '@material-ui/icons/Print';
import InventoryIcon from '@material-ui/icons/Inventory';
import AppRegistrationIcon from '@material-ui/icons/AppRegistration';
import DomainIcon from '@material-ui/icons/Domain';
import FilterIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import MonitorIcon from '@material-ui/icons/Monitor';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';

const sections = [
  {
    title: 'Księgowość',
    permissions: [ 'view firm partners', 'view cash reports', 'edit cash reports', 'view approval payments', 'view sub cash reports', 'view selling report', 'view warehouse report'],
    items: [
      {
        title: 'Raport Kasowy',
        path: '/dashboard/cash-report',
        icon: <ChartSquareBarIcon fontSize="small" />,
        permissions: ['view cash reports'],
      },
      {
        title: 'Raport Sprzedażowy Firm',
        path: '/dashboard/company-selling-report',
        icon: <ChartSquareBarIcon fontSize="small" />,
        permissions: ['view selling report'],
      },
      {
        title: 'Raport Magazynowy Firm',
        path: '/dashboard/company-warehouse-report',
        icon: <ChartSquareBarIcon fontSize="small" />,
        permissions: ['view warehouse report'],
      },
      {
        title: 'Bilans Otwarcia',
        path: '/dashboard/opening-balance',
        icon: <ShoppingBagIcon fontSize="small" />,
        permissions: ['edit cash reports'],
      },
      {
        title: 'Akceptacja wpłat bankowych',
        path: '/dashboard/approval-payments',
        icon: <PriceCheck fontSize="small" />,
        permissions: ['view approval payments'],
      },
      {
        title: 'Akceptacja wpłat z terminali',
        path: '/dashboard/approval-payments-terminal',
        icon: <PriceCheck fontSize="small" />,
        permissions: ['view approval payments'],
      },
      {
        title: 'Akceptacja raportów kasowych',
        path: '/dashboard/approval-cash-reports',
        icon: <PriceCheck fontSize="small" />,
        permissions: ['view sub cash reports'],
      },
      {
        title: 'Operacje',
        path: '/dashboard/operations',
        icon: <ChartPieIcon fontSize="small" />,
        permissions: ['edit cash reports'],
      },
      {
        title: 'Utargi',
        path: '/dashboard/revenues',
        icon: <MonetizationOnIcon fontSize="small" />,
        permissions: ['view cash reports'],
      },
      {
        title: 'Utargi Ręczne',
        path: '/dashboard/manual-revenues',
        icon: <AttachMoneyIcon fontSize="small" />,
        permissions: ['edit cash reports', 'admin'],
      },
      {
        title: 'Korekty faktur',
        path: '/dashboard/invoice-corrections',
        icon: <LibraryBooksIcon fontSize="small" />,
        permissions: ['edit cash reports', 'view cash reports',  'admin'],
      },
    ]
  },
  {
    title: 'Sklepy',
    permissions: ['view shops management', 'view shops monitoring', 'view payment terminal'],
    items: [
      {
        title: 'Zarządzanie sklepami',
        path: '/dashboard/shops-management',
        icon: <ShoppingCartIcon fontSize="small"/>,
        permissions: ['view shops management'],
      },
      {
        title: 'Konta sklepowe',
        path: '/dashboard/shop-accounts',
        icon: <StoreIcon fontSize="small" />,
        permissions: ['view firm partners'],
      },
      {
        title: 'Terminale sklepowe',
        path: '/dashboard/shop-terminals',
        icon: <CreditCardIcon fontSize="small" />,
        permissions: ['view payment terminal'],
      },
      {
        title: 'Monitoring sklepów',
        path: '/dashboard/shops-monitoring',
        icon: <MonitorIcon fontSize="small" />,
        permissions: ['view shops monitoring'],
      },
      {
        title: 'Sklepy stacjonarne-statystyki',
        path: '/dashboard/stationary-shops-statistics',
        icon: <QueryStats fontSize="small" />,
        permissions: ['view shops monitoring'],
      },
    ]
  },
  {
    title: 'Magazyn',
    permissions: ['view warehouse', 'view inventory', 'view products', 'view products categories', 'view products shipment', 'view printers', 'view sectors', 'view product finder', 'view distributing product', 'edit distributing product'],
    items: [
      {
        title: 'Produkty',
        path: '/dashboard/products',
        icon: <AppsIcon fontSize="small"/>,
        permissions: ['view products'],
      },
      {
        title: 'Produkty Wykluczone',
        path: '/dashboard/products-excluded',
        icon: <AppRegistrationIcon fontSize="small"/>,
        permissions: ['view warehouse'],
      },
      {
        title: 'Rozkladanie nowego towaru',
        path: '/dashboard/products-distribution',
        icon: <AllInboxIcon fontSize="small"/>,
        permissions: ['view distributing product', 'edit distributing product'],
      },
      {
        title: 'Uzupełnienie sklepów',
        path: '/dashboard/products-replenishment',
        icon: <Grading fontSize="small"/>,
        permissions: ['view warehouse'],
      },
      {
        title: 'Kategorie',
        path: '/dashboard/product-categories',
        icon: <CategoryIcon fontSize="small"/>,
        permissions: ['view products categories'],
      },
      {
        title: 'Wyszukiwarka towaru',
        path: '/dashboard/product-finder',
        icon: <SearchIcon fontSize="small"/>,
        permissions: ['view product finder'],
      },
      {
        title: 'Wysyłka towaru',
        path: '/dashboard/shipment',
        icon: <LocalShipping fontSize="small"/>,
        permissions: ['view products shipment'],
      },
      {
        title: 'Wysyłka kurierem',
        path: '/dashboard/external-shipment',
        icon: <DeliveryDining fontSize="small"/>,
        permissions: ['view external shipment'],
      },
      {
        title: 'Lista wysyłek',
        path: '/dashboard/shipment-list',
        icon: <LocalShipping fontSize="small"/>,
        permissions: ['view warehouse'],
      },
      {
        title: 'Pudełka wysyłkowe',
        path: '/dashboard/shipment-box-management',
        icon: <LocalShipping fontSize="small"/>,
        permissions: ['view warehouse'],
      },
      {
        title: 'Remanent magazynu',
        path: '/dashboard/inventory',
        icon: <InventoryIcon fontSize="small"/>,
        permissions: ['view inventory', 'view warehouse'],
      },
      {
        title: 'Anulowanie rezerwacji',
        path: '/dashboard/removing-reservation',
        icon: <RemoveShoppingCartIcon fontSize="small"/>,
        permissions: ['view inventory', 'view warehouse'],
      },
      {
        title: 'Drukarki',
        path: '/dashboard/printers',
        icon: <PrintIcon fontSize="small"/>,
        permissions: ['view printers'],
      },
      {
        title: 'Sektory',
        path: '/dashboard/sectors',
        icon: <DomainIcon fontSize="small"/>,
        permissions: ['view sectors', 'view warehouse'],
      },
      {
        title: 'Sortownik-wybieranie',
        path: '/dashboard/sorter',
        icon: <FilterIcon fontSize="small"/>,
        permissions: ['view sorter'],
      },
      {
        title: 'Sortownik-konfiguracja',
        path: '/dashboard/sorter-configuration',
        icon: <TransformIcon fontSize="small" />,
        permissions: ['view sorter'],
      },
      {
        title: 'Sortownik-produkty',
        path: '/dashboard/sorter-products',
        icon: <FilterIcon fontSize="small"/>,
        permissions: ['view sorter products', 'view warehouse'],
      },
      {
        title: 'Zamówienia',
        path: '/dashboard/warehouse-orders',
        icon: <ShoppingBasket fontSize="small"/>,
        permissions: ['view warehouse orders', 'view warehouse'],
      },
    ]
  },
  {
    title: 'V-kioski',
    permissions: ['view kiosks'],
    items: [
      {
        title: 'Status',
        path: '/dashboard/v-kiosks-status',
        icon: <ShoppingCartIcon fontSize="small"/>,
        permissions: ['view kiosks'],
      },
    ]
  },
  {
    title: 'Viola PL',
    permissions: ['view orders'],
    items: [
      {
        title: 'Lista wysyłek',
        path: '/dashboard/shipment-list-viola-pl',
        icon: <AppsIcon fontSize="small"/>,
        permissions: ['view orders'],
      },
      {
        title: 'Statystyki',
        path: '/dashboard/performance-viola-pl',
        icon: <QueryStats fontSize="small"/>,
        permissions: ['view orders'],
      },
      {
        title: 'Raport sprzedażowy',
        path: '/dashboard/sales-report-viola-pl',
        icon: <ChartSquareBarIcon fontSize="small" />,
        permissions: ['view orders'],
      },
    ]
  },
  {
    title: 'Newsy',
    permissions: ['view store application'],
    items: [
      {
        title: 'Powiadomienia',
        path: '/dashboard/notifications',
        icon: <NotificationsIcon fontSize="small" />,
        permissions: ['view store application'],
      },
      {
        title: 'Grupy powiadomień',
        path: '/dashboard/notifications-groups',
        icon: <CampaignIcon fontSize="small" />,
        permissions: ['view store application'],
      },
    ]
  },
  {
    title: 'Kontrole',
    permissions: ['view evidence controls'],
    items: [
      {
        title: 'Kontrole',
        path: '/dashboard/controls',
        icon: <FindInPageIcon fontSize="small" />,
        permissions: ['view evidence controls'],
      },
    ]
  },
  {
    title: 'Kierowcy',
    permissions: ['view cash reports', 'view drivers'],
    items: [
      {
        title: 'Wpłaty Kierowców',
        path: '/dashboard/drivers-cash',
        icon: <DriveEta fontSize="small" />,
        permissions: ['view cash reports'],
      },
      {
        title: 'Lista Kierowców',
        path: '/dashboard/drivers-list',
        icon: <DriveEtaOutlined fontSize="small" />,
        permissions: ['view cash reports', 'view drivers'],
      }
    ]
  },
  {
    title: 'Klient',
    permissions: ['view complaints', 'view orders', 'view product reservations'],
    items: [
      {
        title: 'Reklamacje',
        path: '/dashboard/complaints',
        icon: <AnnouncementIcon fontSize="small" />,
        permissions: ['view complaints'],
      },
      {
        title: 'Zamówienia',
        path: '/dashboard/orders',
        icon: <AnnouncementIcon fontSize="small" />,
        permissions: ['view orders'],
      },
      {
        title: 'Zamówienia dla partnera',
        path: '/dashboard/partner-orders',
        icon: <AnnouncementIcon fontSize="small" />,
        permissions: ['view partner orders'],
      },
      {
        title: 'Wyszukaj po rezerwacji produktu',
        path: '/dashboard/orders-after-booking-product',
        icon: <AnnouncementIcon fontSize="small" />,
        permissions: ['view product reservations', ],
      },
    ]
  },
  {
    title: 'Przerzuty',
    permissions: ['view product transfer'],
    items: [
      {
        title: 'Przerzuty',
        path: '/dashboard/transfers',
        icon: <TransformIcon fontSize="small" />,
        permissions: ['view product transfer'],
      },
      {
        title: 'Konfiguracja',
        path: '/dashboard/transfers-configuration',
        icon: <SettingsIcon fontSize="small" />,
        permissions: ['view product transfer'],
      }
    ]
  },
  {
    title: 'Departamenty',
    permissions: ['view departments'],
    items: [
      {
        title: 'Departamenty',
        path: '/dashboard/departments',
        icon: <AccountBalanceIcon fontSize="small" />,
        permissions: ['view departments'],
      },
      {
        title: 'Lista Pracowników',
        path: '/dashboard/workers-list',
        icon: <EmojiPeopleIcon fontSize="small" />,
        permissions: ['view departments'],
      },
      {
        title: 'Urlopy',
        path: '/dashboard/vacations',
        icon: <LocalHospital fontSize="small" />,
        permissions: ['view departments'],
      },
    ]
  },
  {
    title: 'Użytkownicy',
    permissions: ['admin'],
    items: [
      {
        title: 'Lista Użytkowników',
        path: '/dashboard/users-list',
        icon: <UserIcon fontSize="small" />,
        permissions: ['admin'],
      },
      {
        title: 'Role i uprawnienia',
        path: '/dashboard/permissions',
        icon: <AccessibilityIcon fontSize="small" />,
        permissions: ['admin'],
      }
    ]
  },
];

// styling
const useStyles = makeStyles(theme => ({
  avatar: {
    textDecoration: 'none',
    color: '#f4f5f7',
    backgroundColor: theme.palette.primary.main,
  },
  userDetails: {
    fontSize: 12
  }
}))

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const classes = useStyles();

  const checkPermissions = (requiredPermissions, checkedPermissions) => checkedPermissions.some(v => requiredPermissions.includes(v));


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
      >
        <Scrollbar options={{ suppressScrollX: true }}>
          <Hidden lgUp>
            <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 2
                }}
            >
              <RouterLink
                  to="/"
                  style={{ textDecoration: 'none' }}
              >
                <Logo/>
              </RouterLink>
            </Box>
          </Hidden>
          <Box sx={{ p: 2 }}>
            <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  display: 'flex',
                  overflow: 'hidden',
                  p: 2
                }}
            >
              {/*<RouterLink to="/dashboard/account">*/}
              <AvatarInitials username={user.name}/>
              {/*</RouterLink>*/}
              <Box sx={{ ml: 2 }}>
                <Typography
                    color="textPrimary"
                    variant="subtitle2"
                >
                  {user.name + ' ' + user.surname}
                </Typography>
                <Typography
                    color="textSecondary"
                    variant="body2"
                    className={classes.userDetails}
                    component='span'
                >
                  <Link
                      color="primary"
                      component={RouterLink}
                      to="/pricing"
                  >
                    {user.plan}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box style={{paddingTop: 0}} sx={{ p: 2}}>
            {sections.filter(section => (
                checkPermissions(section.permissions, user.permission) || section.permissions.includes('all')
            ))
                .map((section) => (
                    <NavSection
                        key={section.title}
                        pathname={location.pathname}
                        permission={user.permission}
                        style={{ margin: 0, padding: 0}}
                        sx={{
                          '& + &': {
                            mt: 3
                          }
                        }}
                        {...section}
                    />
                ))}
          </Box>
        </Scrollbar>
      </Box>
  );

  return (
      <>
        <Hidden lgUp>
          <Drawer
              anchor="left"
              onClose={onMobileClose}
              open={openMobile}
              PaperProps={{
                sx: {
                  backgroundColor: 'background.paper',
                  width: 280
                }
              }}
              variant="temporary"
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden lgDown>
          <Drawer
              anchor="left"
              open
              PaperProps={{
                sx: {
                  backgroundColor: 'background.paper',
                  height: 'calc(100% - 64px) !important',
                  top: '64px !Important',
                  width: 280
                }
              }}
              variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
      </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
